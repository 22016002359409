import _ from "lodash"

import { LineItem } from "../context/CartContext"
import { Promotion } from "../models/promotion"
import dayjs, { Dayjs } from "dayjs"

export const minToHourFormat = (minutes: number): string => {
  const timeInHours = Math.floor(minutes / 60)
  const timeInMinutes = minutes % 60

  if (timeInHours > 0) {
    if (timeInMinutes > 0) {
      return `${timeInHours}h${timeInMinutes}m`
    }
    return `${timeInHours} hours`
  }
  return `${timeInMinutes} mins`
}

export const currencyFormat = (value: number, currencyCode: string): string => {
  return `${currencyCode} ${value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}

export const calculateCartTotal = (
  lineItems: LineItem[],
  promotions: Promotion[]
): { totalDiscount: number; total: number } => {
  const subtotal: number = getFloat(
    _.reduce(
      lineItems,
      (sum, lineItem) => {
        return sum + Number(lineItem.item.price) * Number(lineItem.quantity)
      },
      0
    ),
    2
  )

  let totalDiscount: number = 0

  promotions &&
    promotions.forEach(promo => {
      if (promo.type === "percentage") {
        totalDiscount += getFloat(subtotal * (promo.value / 100), 2)
      } else {
        totalDiscount += getFloat(promo.value, 2)
      }
    })

  return { totalDiscount, total: subtotal - totalDiscount }
}

export const calculateCartTotalEstTime = (lineItems: LineItem[]): number => {
  return _.reduce(
    lineItems,
    (sum, lineItem) => {
      return sum + Number(lineItem.item.estimate_service_time)
    },
    0
  )
}

export const getFloat = (value: number, digits: number): number => {
  return Number(parseFloat(value.toString()).toFixed(digits))
}

export function timeToDate(hhmm: string): Dayjs {
  const [hh, mm] = hhmm.split(":")
  return dayjs().hour(Number(hh)).minute(Number(mm)).second(0)
}

export function calNextAvailableTime(
  curTimeInHour: number,
  minimumServicetime: number
): string {
  let hourPart: number = Math.floor(curTimeInHour)
  let minutePart: number =
    (curTimeInHour - Math.floor(curTimeInHour)) * 60 + minimumServicetime

  if (minutePart >= 60) {
    hourPart += Math.floor(minutePart / 60)
    minutePart = minutePart % 60
  }

  let hour: string = hourPart < 10 ? `0${hourPart}` : hourPart.toString()
  let minute: string =
    minutePart < 10 ? `0${minutePart}` : minutePart.toString()

  return `${hour}:${minute}`
}

export function getTimePickerInHour(hhmm: string): number {
  const timeTokens: string[] = hhmm.split(":")

  const hourValue: number = Number(timeTokens[0])
  const minuteValue: number = Number(timeTokens[1])

  return hourValue + minuteValue / 60
}
export function isToday(currentDate: string): boolean {
  return (
    dayjs().format("MM-DD-YYYY") === dayjs(currentDate).format("MM-DD-YYYY")
  )
}
